import { AddIcon, DownloadIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'

import React, { useCallback, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import NurseList from '../Components/NurseList'

export const medicalRecordReview = [
  '1. Record includes appropriate lab work',
  '2. Record includes consent form',
  '3. Record includes post-op instruction',
  '4. Notation: specimen removed and sent to pathology',
  '5. If Specimen removed, notation agrees with lab book',
  '6. Patient discharged home',
  '7. Patient transferred to hospital',
]

export const clinical = [
  '1. Pre-op phone call completed or at least attempted twice and documented',
  '2. Allergies and/or abnormal drug reactions documented',
  '3. Pre-op nursing assessment w/medications complete and signed',
  '4. Pre-op diagnostic studies available and addressed',
  '5. Physician alerted to abnormal lab values',
  '6. H&P available, signed and dated',
  '7. Operative Report complete and signed',
  '8. Patient discharged by physician visit, order or criteria',
  '9. PACU record complete and signed',
  '10. Post-op instruction sheet signed by responsible party',
  '11. Post-op call documented, if unable to reach, document attempts and follow-up letter',
  '12. Patient response to all treatment documented',
]

export const allAreas = [
  '1. All entries complete with signatures and dates',
  '2. Verbal orders are authenticated',
  '3. All entries are legible',
]

function FormNurse({ onSubmit, data, loading, facility }) {
  const params = useParams()
  const history = useHistory()
  const { handleSubmit, register, errors, control, setValue, watch, trigger } =
    useForm()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form.list',
  })

  const isCreate = !!params.id

  const initData = useCallback(() => {
    if (!data) return append({})
    return data?.list?.map(item => {
      return append(item)
    })
  }, [append, data])

  useEffect(() => {
    initData()
  }, [initData])

  return (
    <>
      <Box>
        <Stack alignItems="center" spacing="1rem">
          <Image
            src={facility === 'PCSO' ? '/paincare-web.png' : '/LogoOSSC.jpeg'}
            width="325px"
          />
          <Text align="center" fontSize="xs">
            {facility === 'PCSO' ? (
              <>
                2480 Liberty Street NE, Suite 180, Salem, OR 97301 <br />
                Phone: (503) 371-1010 Fax: (503) 371-0805
              </>
            ) : (
              <>
                Oregon Specialists Surgery Center 2785 River Road S, Salem, OR
                97302
              </>
            )}
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            NURSE PEER REVIEW WORKSHEET
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            CHECK EACH BOX AS: Adequate + Inadequate -- Not Applicable n.a.
          </Text>
          <Text> PEER REVIEW: 15 Randomly selected charts each quarter.</Text>
        </Stack>
      </Box>
      <Box mt="4rem">
        <Flex w="100%" justifyContent="end">
          <Button
            leftIcon={<DownloadIcon />}
            colorScheme="blue"
            isDisabled={params?.id ? false : true}
            onClick={() => history.push(`/peer-review/pdf/${params?.id}`)}
          >
            EXPORT PDF
          </Button>
        </Flex>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} id="time-away-request-form">
        {fields.map((_, index) => {
          return (
            <NurseList
              key={index}
              index={index}
              errors={errors}
              register={register}
              control={control}
              data={data}
              setValue={setValue}
              watch={watch}
              trigger={trigger}
              remove={remove}
              medicalRecordReview={medicalRecordReview}
              clinical={clinical}
              allAreas={allAreas}
              isCreate={isCreate}
            />
          )
        })}

        {fields.length > 0 && (
          <hr style={{ backgroundColor: 'black', height: 1 }} />
        )}

        <Flex justifyContent="end" my={4} pb={8}>
          <ButtonGroup>
            <Button
              variant="outline"
              leftIcon={<AddIcon />}
              onClick={() => append({})}
              isDisabled={fields.length === 15 ? true : false}
            >
              Add
            </Button>
            <Button type="submit" isLoading={loading}>
              Submit
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    </>
  )
}

export default FormNurse
