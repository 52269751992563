import React from 'react'
import { Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({
  page: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingLeft: 40,
    paddingRight: 40,
  },
  providerBox: {
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
    margin: '24px 0px',
  },
  boxNumber: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    margin: '14px 0px 0px 0px',
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
  },
  textBorder: {
    borderBottom: 1,
    textAlign: 'center',
  },
  sectionSignature: {
    width: '100%',
    marginTop: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  signatureBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
  },
  signature: {
    top: 4,
    paddingTop: 4,
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: 1,
  },
  textsignature: {
    fontSize: 10,
  },
})

const list = new Array(15).fill('')

export default function Cover({ data }) {
  const period = data?.list?.[0]?.period
    ? `${moment(data?.list?.[0]?.period).format(`YYYY`)}-Q${moment(
        data?.list?.[0]?.period
      ).format(`Q`)}`
    : ''

  const isNurse = data?.type && data?.type === 'Nurses'

  return (
    <Page size="A4" style={styles.page}>
      <Text
        style={{
          textAlign: 'center',
          fontSize: '15px',
          margin: '34px 0px 0px 0px',
        }}
      >
        {isNurse
          ? 'Nurse Peer Review/Utilization Review Quarterly Log'
          : 'Peer Review/Utilization Review Quarterly Log'}
      </Text>
      {isNurse ? (
        <View style={styles.providerBox}>
          <View style={styles.boxTitle}>
            <Text style={styles.textTitle}>Review Period: </Text>
            <Text style={styles.textBorder}>{period}</Text>
          </View>
        </View>
      ) : (
        <View style={styles.providerBox}>
          <View style={styles.boxTitle}>
            <Text style={styles.textTitle}>Provider : </Text>
            <Text style={styles.textBorder}>
              {data?.list?.[0]?.anesthesia || ''}
            </Text>
          </View>
          <View style={styles.boxTitle}>
            <Text style={styles.textTitle}>Review Period: </Text>
            <Text style={styles.textBorder}>{period}</Text>
          </View>
        </View>
      )}

      {isNurse ? (
        <Text style={{ fontSize: '14px', width: '100%' }}>
          REVIEW: 5% up to a maximum of 15 records/quarter
        </Text>
      ) : (
        <Text style={{ fontSize: '14px' }}>
          REVIEW: 5% up to a maximum of 5 records/provider/quarter, minimum of 3
          records must be reviewed/provider/quarter. If less than 3 surgeries
          have been completed then all records during that quarter must be
          reviewed. All untoward events must be reviewed in addition to the 5%.
        </Text>
      )}
      <View style={styles.boxNumber}>
        <Text style={styles.textTitle}>
          Number of cases performed by provider this quarter:{' '}
        </Text>
        <Text style={styles.textBorder}>{data?.performed || 0}</Text>
      </View>
      <View style={styles.boxNumber}>
        <Text style={styles.textTitle}>
          Number of cases reviewed this quarter:{' '}
        </Text>
        <Text style={styles.textBorder}>{data?.list?.length || 0}</Text>
      </View>
      <View style={{ marginTop: '14px', fontSize: '14px' }}>
        <Text>Medical Records Number of Cases reviewed</Text>
      </View>
      <View style={{ flexDirection: 'row', marginTop: '12px' }}>
        <View style={{ borderWidth: '2px', width: '40%' }}>
          <Text style={{ fontSize: '12px', padding: '4px' }}>
            Random Records Reviewed
          </Text>
        </View>
        <View style={{ borderWidth: '2px', width: '40%' }}>
          <Text style={{ fontSize: '12px', padding: '4px' }}>
            Untoward Events Reviewed
          </Text>
        </View>
      </View>
      {list.map((_, index) => {
        return (
          <View key={index} style={{ flexDirection: 'row' }}>
            <View style={{ borderWidth: '1px', width: '40%' }}>
              <Text
                style={{
                  fontSize: '12px',
                  padding: '4px 0px',
                  textAlign: 'center',
                }}
              >
                {data?.list?.[index]?.dos || ''} {data?.list?.[index]?.mr || ''}
              </Text>
            </View>
            <View style={{ borderWidth: '1px', width: '40%' }}>
              <Text style={{ fontSize: '12px' }}></Text>
            </View>
          </View>
        )
      })}
      <View style={{ marginTop: '14px', fontSize: '12px' }}>
        <Text>
          All cases above were reviewed by the Governing Body and Medical
          Director.
        </Text>
      </View>
      <View style={styles.sectionSignature}>
        <View style={styles.signatureBox}>
          <Text style={styles.textsignature}></Text>
          <Text style={styles.signature}>
            <Text style={styles.textsignature}>Medical Director</Text>
          </Text>
        </View>
        <View style={styles.signatureBox}>
          <Text style={styles.textsignature}></Text>
          <Text style={styles.signature}>
            <Text style={styles.textsignature}>Date</Text>
          </Text>
        </View>
      </View>
      <View style={styles.sectionSignature}>
        <View style={styles.signatureBox}>
          <Text style={styles.textsignature}></Text>
          <Text style={styles.signature}>
            <Text style={styles.textsignature}>
              Governing Body Representative
            </Text>
          </Text>
        </View>
        <View style={styles.signatureBox}>
          <Text style={styles.textsignature}></Text>
          <Text style={styles.signature}>
            <Text style={styles.textsignature}>Date</Text>
          </Text>
        </View>
      </View>
    </Page>
  )
}
