import {
  Image,
  PDFViewer,
  StyleSheet,
  Document,
  Page,
  View,
  Text,
} from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'
import { allAreas, clinical, medicalRecordReview } from '../form/FormNurse'
import Cover from './cover'

const styles = StyleSheet.create({
  pdfView: {
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: '#fff',
  },
  page: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 40,
  },
  pageBorder: {
    width: '100%',
    height: '100%',
    border: '2px solid black',
  },
  sectionImg: {
    width: '100%',
    margin: '24px 0px',
    alignItems: 'center',
  },
  img: {
    width: '200px',
    height: 'auto',
    objectFit: 'cover',
  },
  textHeader: {
    fontSize: '12px',
    marginBottom: '6px',
    textAlign: 'center',
  },
  sectionTwo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '12px',
    fontSize: '12px',
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  textBorderPeriod: {
    borderBottom: 1,
    textAlign: 'center',
    width: '80px',
  },
  textBorder: {
    borderBottom: 1,
    textAlign: 'center',
  },
  boxDividerWithTop: {
    width: '100%',
    marginTop: '24px',
    borderBottomWidth: '2px',
  },
  boxDivider: {
    width: '100%',
    borderBottomWidth: '2px',
  },
  sectionInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '10px',
    padding: '8px 8px',
  },

  sectionthree: {
    width: '100%',
    marginBottom: 10,
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColTitleHeader: {
    width: '85%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: 'flex',
    justifyContent: 'center',
    fontSize: '10px',
    textAlign: 'center',
    backgroundColor: '#C5C5C5',
  },
  tableColTitle: {
    width: '85%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColSecondValueKeyTitle: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#C5C5C5',
  },
  tableColSecondValueKey: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColFirstValueKey: {
    paddingTop: 5,
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColThreeValueKeyTitle: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#C5C5C5',
  },
  tableColThreeValueKey: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    marginTop: 2,
    fontSize: 10,
    textAlign: 'center',
  },
  tableCell: {
    margin: 'auto',
    marginY: 1,
    fontSize: 10,
  },
  tableCellQuestion: {
    margin: '1px 2px',
    fontSize: 10,
  },
  sectionSignature: {
    width: '100%',
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
  },
  signatureBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
  },
  signatureImg: {
    height: '40px',
  },
  signature: {
    top: 4,
    paddingTop: 4,
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: 1,
  },
  textsignature: {
    fontSize: 10,
  },
})

function RenderNursesPDF({ data }) {
  const checked = (key, value) => {
    if (key !== value) return ''
    return <Image style={styles.imgcheck} src="/check.png" />
  }

  const RenderQuestion = ({
    title = '',
    question = [],
    valueKey = '',
    index,
  }) => {
    return (
      <>
        <View style={styles.tableRow}>
          <View style={styles.tableColTitleHeader}>
            <Text style={styles.tableCellQuestion}>{title}</Text>
          </View>
          <View style={styles.tableColSecondValueKeyTitle}>
            <Text style={styles.tableCellHeader}>Y</Text>
          </View>
          <View style={styles.tableColSecondValueKeyTitle}>
            <Text style={styles.tableCellHeader}>N</Text>
          </View>
          <View style={styles.tableColThreeValueKeyTitle}>
            <Text style={styles.tableCellHeader}>N/A</Text>
          </View>
        </View>
        {question.map((item, qIndex) => (
          <View key={qIndex} style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCellQuestion}>{item}</Text>
            </View>
            <View style={styles.tableColSecondValueKey}>
              <Text style={styles.tableCell}>
                {checked(data.list?.[index]?.[valueKey]?.[qIndex], 'yes')}
              </Text>
            </View>
            <View style={styles.tableColSecondValueKey}>
              <Text style={styles.tableCell}>
                {checked(data?.list?.[index]?.[valueKey]?.[qIndex], 'no')}
              </Text>
            </View>
            <View style={styles.tableColThreeValueKey}>
              <Text style={styles.tableCell}>
                {checked(data?.list?.[index]?.[valueKey]?.[qIndex], 'n/a')}
              </Text>
            </View>
          </View>
        ))}
      </>
    )
  }

  return (
    <PDFViewer style={styles.pdfView}>
      <Document>
        <Cover data={data} />
        {data?.list?.map((item, index) => {
          return (
            <Page key={index} size="A4" style={styles.page}>
              <View style={styles.pageBorder}>
                <View style={styles.sectionImg}>
                  <Image
                    style={styles.img}
                    src={
                      data.facility === 'PCSO'
                        ? '/paincare-web.png'
                        : '/LogoOSSC.jpeg'
                    }
                  />
                </View>
                <Text style={styles.textHeader}>
                  NURSE PEER REVIEW WORKSHEET
                </Text>
                <Text style={styles.textHeader}>
                  CHECK EACH BOX AS: Adequate + Inadequate -- Not Applicable
                  n.a.
                </Text>
                <Text style={styles.textHeader}>
                  PEER REVIEW: 15 Randomly selected charts each quarter.
                </Text>
                <View style={styles.sectionTwo}>
                  <View style={styles.boxTitle}>
                    <Text style={styles.textTitle}>Review Period : </Text>
                    <Text style={styles.textBorderPeriod}>
                      {moment(item.period).format('YYYY')}
                    </Text>
                  </View>
                  <View style={styles.boxTitle}>
                    <Text style={styles.textTitle}> - Quarter : </Text>
                    <Text style={styles.textBorderPeriod}>
                      {moment(item.period).format('Q')}
                    </Text>
                  </View>
                </View>
                <View style={styles.boxDividerWithTop} />
                <View style={styles.sectionInfo}>
                  <View style={styles.boxTitle}>
                    <Text>Surgeon : </Text>
                    <Text style={styles.textBorder}>
                      {item?.surgeon || '-'}
                    </Text>
                  </View>
                  <View style={styles.boxTitle}>
                    <Text>Anesthesia Provider : </Text>
                    <Text style={styles.textBorder}>
                      {item?.anesthesia || '-'}
                    </Text>
                  </View>
                  <View style={styles.boxTitle}>
                    <Text>MR# : </Text>
                    <Text style={styles.textBorder}>{item?.mr || ''}</Text>
                  </View>
                  <View style={styles.boxTitle}>
                    <Text>DOS : </Text>
                    <Text style={styles.textBorder}>{item?.dos || '-'}</Text>
                  </View>
                </View>
                <View style={styles.boxDivider} />
                <View style={styles.sectionthree}>
                  <View style={styles.table}>
                    <RenderQuestion
                      title="MEDICAL RECORD REVIEW"
                      question={medicalRecordReview}
                      valueKey="medicalAnswers"
                      index={index}
                    />
                    <RenderQuestion
                      title="CLINICAL"
                      question={clinical}
                      valueKey="clinicalAnswers"
                      index={index}
                    />
                    <RenderQuestion
                      title="ALL AREAS"
                      question={allAreas}
                      valueKey="allAreasAnswers"
                      index={index}
                    />
                  </View>
                </View>
                <View style={styles.sectionSignature}>
                  <View style={styles.signatureBox}>
                    {item?.signatureCollector && (
                      <Image
                        style={styles.signatureImg}
                        src={item?.signatureCollector}
                      />
                    )}
                    <Text style={styles.signature}>
                      <Text style={styles.textsignature}>
                        Chart Reviewer Signature
                      </Text>
                    </Text>
                  </View>
                  <View style={styles.signatureBox}>
                    <Text style={styles.textsignature}>
                      {item?.date
                        ? moment(item?.date).format('MM/DD/YYYY')
                        : ' '}
                    </Text>
                    <Text style={styles.signature}>
                      <Text style={styles.textsignature}>Date</Text>
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          )
        })}
      </Document>
    </PDFViewer>
  )
}

export default RenderNursesPDF
